import React from "react"
import "../sass/screen.scss"
import Wing from "../assets/wing.svg"
import WingInvers from "../assets/wing-invers.svg"

interface LayoutProps {
  greenVer?: boolean
}

const Wings: React.FC<LayoutProps> = props => {
  const { greenVer } = props

  return (
    <section className="wing">
      <img className="left" src={greenVer ? WingInvers : Wing} />
      <img className="right" src={greenVer ? WingInvers : Wing} />
    </section>
  )
}

export default Wings
