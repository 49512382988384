import React from "react"
import styled from "styled-components"
import { breakpoints, colors, fonts } from "../../styles/variables"

const StyledCopyright = styled.div`
  text-align: center;
  background-color: ${colors.background.footer};
`

const CopyrightText = styled.div`
  font-family: ${fonts.light.condensed};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.9px;
  color: #000000;
  padding-top: 18px;
  padding-bottom: 18px;
`

const Copyright: React.FC = () => {
  return (
    <StyledCopyright>
      <CopyrightText>
        © {new Date().getFullYear()} SCHWARZ Optik -{" "}
        <a href="/datenschutz">Datenschutzerklärung</a>
      </CopyrightText>
    </StyledCopyright>
  )
}

export default Copyright
