import React from "react"
import { SetStateAction, Dispatch } from "react"
import "../sass/screen.scss"
import Hamburger from "../assets/mobile-navi-opener.svg"
import HamburgerCloser from "../assets/mobile-navi-closer.svg"
import Logo from "../assets/schwarz-optik-wortmarke.svg"

interface HeaderProps {
  isMenuOpen: boolean
  setMenuOpen: Dispatch<SetStateAction<boolean>>
}

const Header: React.FC<HeaderProps> = ({ isMenuOpen, setMenuOpen }) => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={Logo} />
        </a>
      </div>
      <div
        className="mobileNav-opener"
        onClick={() => {
          setMenuOpen(!isMenuOpen)
        }}
      >
        {!isMenuOpen ? (
          <img src={Hamburger} alt="Navigation" />
        ) : (
          <img src={HamburgerCloser} alt="Navigation" />
        )}
      </div>
    </header>
  )
}

export default Header
