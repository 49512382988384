/* eslint-disable max-len */

export const colors = {
  main: "#00aa9b",
  error: "#f3b4b4",
  success: "#b0e5af",
  info: "lighten(#00aa9b, 53%)",
  highlight: "#00aa9b",
  overlayBg: "rgba(#161616, 9/10)",
  overlayText: "#f1f1f1",
  background: {
    footer: "#f2f6f6",
  },
}

export const fonts = {
  light: {
    condensed: "Helvetica Neue LT W05_47 Lt Cn, sans-serif",
  },
  thin: "Helvetica Neue LT W05_35 Thin, sans-serif",
  condensed: "Helvetica Neue LT W05_47 Lt Cn, Roboto Condensed, sans-serif",
  "light-uncondensed": "Helvetica Neue LT W05_45 Light, sans-serif",
  roman: "Roboto, sans-serif",
  "roman-cond": "Helvetica Neue LT W05_57 Cond, sans-serif",
  bold: "Helvetica Neue LT W05_77 Bd Cn, sans-serif",
  "heavy-condensed": "Helvetica Neue LT W05_87 Hv Cn, sans-serif",
  special: "Officina",
}

export const breakpoints = {
  max: 1920,
  desktop: 1200,
  normal: 920,
  mobile: 320,
}
