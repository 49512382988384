import React, { Component, useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import "../sass/screen.scss"
import { SetStateAction, Dispatch } from "react"
import Logo from "../assets/schwarz-optik-logo.svg"

interface NavigationProps {
  children?: any
  background?: string
  isMenuOpen: boolean
  setMenuOpen: Dispatch<SetStateAction<boolean>>
}

const Navigation: React.FC<NavigationProps> = ({
  children,
  background,
  isMenuOpen,
}) => {
  return (
    <section className="wrapper">
      <nav className={classnames("mainNav", { "is-open": isMenuOpen })}>
        <div className="scroller">
          <div className="mainNav-list">
            <ul>
              <li className="mainNav-list-item">
                <Link
                  to="/dienstleistungen/"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  <span>Dienstleistungen</span>
                </Link>
              </li>
              <li className="mainNav-list-item">
                <Link
                  to="/angebot/"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  <span>Angebot</span>
                </Link>
              </li>
              <li className="mainNav-list-item logo">
                <Link to="/" activeClassName="active" partiallyActive={true}>
                  <span>
                    <img src={Logo} alt="SCHWARZ Optik" />
                  </span>
                </Link>
              </li>
              <li className="mainNav-list-item">
                <Link
                  to="/schwarz-optik/"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  <span>SCHWARZ Optik</span>
                </Link>
              </li>
              <li className="mainNav-list-item">
                <Link to="/blog/" activeClassName="active">
                  <span>Blog</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  )
}

export default Navigation
