import React, { Component, useState } from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import "../sass/screen.scss"
import LogoDesktop from "../assets/schwarz-optik-wortmarke.svg"
import LogoMobile from "../assets/schwarz-optik-logo.svg"
import Instagram from "../assets/instagram.svg"
import styled from "styled-components"

import Optikschweiz from "../assets/logos/logo-optik-schweiz.svg"
import SBAO from "../assets/logos/logo-sbao.svg"
import VDCO from "../assets/logos/logo-vdco.svg"
import IVBS from "../assets/logos/logo-ivbs.svg"
import OEPF from "../assets/logos/logo-oepf.svg"

const Column = styled.div``
const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 10px;
  a {
    display: flex;
  }
`
const Mitgliedlinks = styled.div`
  a {
    color: black;
    padding-left: 5px;
    padding-right: 5px;

    &:first-of-type {
      padding-left: 0;
    }
  }
`
const Mitgliedarea = styled.div`
  width: 265px;
  height: 77px;
  margin: 0 auto;
`

const Footer = () => {
  return (
    <footer className="wrapper footer">
      <section className="left">
        <img src={LogoDesktop} alt="SCHWARZ Optik" className="desktop" />
        <img src={LogoMobile} alt="SCHWARZ Optik" className="mobile" />
        <br />
        <strong>SCHWARZ Optik GmbH</strong>
        <br />
        Rosentalstrasse 8a • CH-9410 Heiden
        <br />
        <span className="nobr">T +41 71 890 01 11</span> <span> • </span>
        <a href="tel:+41718900112" className="nobr">
          F +41 71 890 01 12
        </a>
        <br />
        <a href="mailto:info@schwarz-optik.ch">info@schwarz-optik.ch</a>
        <br />
        <br />
        <a href="https://www.instagram.com/schwarzoptik/" target="_blank">
          <img className="icon" src={Instagram} alt="Instagram" />
        </a>
      </section>
      <section className="middle">
        <strong>Öffnungszeiten</strong>
        <br />
        Dienstag bis Freitag
        <br />
        08:30-12:00 und 13:30-18:30
        <br />
        Samstag
        <br />
        08:30-12:00 und 13:30-16:00
        <br />
        Montag Ruhetag
      </section>
      <section className="right">
        <Mitgliedarea>
          <Column>
            <Row>
              <a
                href="https://www.optikschweiz.ch/"
                target="blank"
                style={{ width: "180px", height: "30px" }}
              >
                <img
                  src={Optikschweiz}
                  alt="Verband für Optometrie und
          Optik"
                />
              </a>
              <a
                href="https://sbao.ch/"
                target="blank"
                style={{ width: "55px", height: "30px" }}
              >
                <img
                  src={SBAO}
                  alt="Schweizerischer Berufsverband
          für Augenoptik und Optometrie"
                />
              </a>
            </Row>
            <Row>
              <a
                href="https://www.vdco.de/"
                target="blank"
                style={{ width: "60px", height: "30px" }}
              >
                <img
                  src={VDCO}
                  alt="Vereinigung Deutscher
          Contactlinsen-Spezialisten und Optometristen"
                />
              </a>
              <a
                href="https://www.ivbs.org/"
                target="blank"
                style={{ width: "55px", height: "30px" }}
              >
                <img
                  src={IVBS}
                  alt="PartInternationale Vereinigung für
          Binokulares Sehenner"
                />
              </a>
              <a
                href="https://www.oepf.org/"
                target="blank"
                style={{ width: "60px", height: "30px" }}
              >
                <img
                  src={OEPF}
                  alt="Fellow of the Optometric Extention Program"
                />
              </a>
            </Row>
          </Column>
        </Mitgliedarea>

        <br />
        <div className="is-hidden-mobile">
          <Mitgliedlinks>
            <a href="https://www.optikschweiz.ch/" target="blank">
              <strong>Mitglied OPTIKSCHWEIZ</strong> – Verband für Optometrie
              und Optik
            </a>
            <a href="https://sbao.ch/" target="blank">
              <strong>Mitglied SBAO</strong> – Schweizerischer Berufsverband für
              Augenoptik und Optometrie
            </a>
            /
            <a href="https://www.vdco.de/" target="blank">
              <strong>Mitglied VDCO</strong> – Vereinigung Deutscher
              Contactlinsen-Spezialisten und Optometristen
            </a>
            <a href="https://www.ivbs.org/" target="blank">
              <strong>Mitglied IVBS</strong> – Internationale Vereinigung für
              Binokulares Sehen
            </a>
            /
            <a href="https://www.oepf.org/" target="blank">
              <strong>Mitglied OEP-F</strong> – Optometric Extention Program
            </a>
          </Mitgliedlinks>
        </div>
      </section>
    </footer>
  )
}

export default Footer
