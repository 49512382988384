import React, { useState } from "react"
import "../sass/screen.scss"
import Copyright from "./footer/Copyright"
import Navigation from "./Navigation"
import Header from "./Header"
import Footer from "./Footer"
import Wings from "./Wings"

interface LayoutProps {
  title: string
  children?: any
  background?: string
  greenVer?: boolean
}

const Layout: React.FC<LayoutProps> = ({
  title,
  children,
  background,
  greenVer,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <div>
      <Header isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      <Navigation isMenuOpen={isMenuOpen} />
      <div
        className={greenVer ? "greenVer" : "defaultVer"}
        style={{ background: background }}
      >
        {children}
      </div>
      <Footer />
      <Wings greenVer={greenVer} />
      <Copyright />
    </div>
  )
}

export default Layout
